/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const ButtonPrimaryBorderWidthDefault = '0px'
export const ButtonPrimaryBorderWidthHovered = '0px'
export const ButtonPrimaryBorderWidthPressed = '0px'
export const ButtonPrimaryBorderWidthDisabled = '0px'
export const ButtonPrimaryColorPositiveDefaultBackground = '#ED9341'
export const ButtonPrimaryColorPositiveDefaultOnBackground = '#ffffff'
export const ButtonPrimaryColorPositiveDefaultOutline = '#ffffff00'
export const ButtonPrimaryColorPositiveHoveredBackground = '#d8873d'
export const ButtonPrimaryColorPositiveHoveredOnBackground = '#ffffff'
export const ButtonPrimaryColorPositiveHoveredOutline = '#ffffff00'
export const ButtonPrimaryColorPositivePressedBackground = '#c37a38'
export const ButtonPrimaryColorPositivePressedOnBackground = '#ffffff'
export const ButtonPrimaryColorPositivePressedOutline = '#ffffff00'
export const ButtonPrimaryColorPositiveDisabledBackground = '#0000000a'
export const ButtonPrimaryColorPositiveDisabledOnBackground = '#00000061'
export const ButtonPrimaryColorPositiveDisabledOutline = '#ffffff00'
export const ButtonPrimaryColorDestructiveDefaultBackground = '#f4511e'
export const ButtonPrimaryColorDestructiveDefaultOnBackground = '#ffffff'
export const ButtonPrimaryColorDestructiveDefaultOutline = '#ffffff00'
export const ButtonPrimaryColorDestructiveHoveredBackground = '#df4b1d'
export const ButtonPrimaryColorDestructiveHoveredOnBackground = '#ffffff'
export const ButtonPrimaryColorDestructiveHoveredOutline = '#ffffff00'
export const ButtonPrimaryColorDestructivePressedBackground = '#c9451c'
export const ButtonPrimaryColorDestructivePressedOnBackground = '#ffffff'
export const ButtonPrimaryColorDestructivePressedOutline = '#ffffff00'
export const ButtonPrimaryColorDestructiveDisabledBackground = '#0000000a'
export const ButtonPrimaryColorDestructiveDisabledOnBackground = '#00000061'
export const ButtonPrimaryColorDestructiveDisabledOutline = '#ffffff00'
export const ButtonPrimaryColorWarningDefaultBackground = '#fb8c00'
export const ButtonPrimaryColorWarningDefaultOnBackground = '#ffffff'
export const ButtonPrimaryColorWarningDefaultOutline = '#ffffff00'
export const ButtonPrimaryColorWarningHoveredBackground = '#e58007'
export const ButtonPrimaryColorWarningHoveredOnBackground = '#ffffff'
export const ButtonPrimaryColorWarningHoveredOutline = '#ffffff00'
export const ButtonPrimaryColorWarningPressedBackground = '#cf740d'
export const ButtonPrimaryColorWarningPressedOnBackground = '#ffffff'
export const ButtonPrimaryColorWarningPressedOutline = '#ffffff00'
export const ButtonPrimaryColorWarningDisabledBackground = '#0000000a'
export const ButtonPrimaryColorWarningDisabledOnBackground = '#00000061'
export const ButtonPrimaryColorWarningDisabledOutline = '#ffffff00'
