/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const IconButtonPrimaryBorderWidthDefault = '0px'
export const IconButtonPrimaryBorderWidthHovered = '0px'
export const IconButtonPrimaryBorderWidthPressed = '0px'
export const IconButtonPrimaryBorderWidthDisabled = '0px'
export const IconButtonPrimaryColorPositiveDefaultBackground = '#ED9341'
export const IconButtonPrimaryColorPositiveDefaultOnBackground = '#ffffff'
export const IconButtonPrimaryColorPositiveDefaultOutline = '#ffffff00'
export const IconButtonPrimaryColorPositiveHoveredBackground = '#d8873d'
export const IconButtonPrimaryColorPositiveHoveredOnBackground = '#ffffff'
export const IconButtonPrimaryColorPositiveHoveredOutline = '#ffffff00'
export const IconButtonPrimaryColorPositivePressedBackground = '#c37a38'
export const IconButtonPrimaryColorPositivePressedOnBackground = '#ffffff'
export const IconButtonPrimaryColorPositivePressedOutline = '#ffffff00'
export const IconButtonPrimaryColorPositiveDisabledBackground = '#0000000a'
export const IconButtonPrimaryColorPositiveDisabledOnBackground = '#00000061'
export const IconButtonPrimaryColorPositiveDisabledOutline = '#ffffff00'
export const IconButtonPrimaryColorDestructiveDefaultBackground = '#f4511e'
export const IconButtonPrimaryColorDestructiveDefaultOnBackground = '#ffffff'
export const IconButtonPrimaryColorDestructiveDefaultOutline = '#ffffff00'
export const IconButtonPrimaryColorDestructiveHoveredBackground = '#df4b1d'
export const IconButtonPrimaryColorDestructiveHoveredOnBackground = '#ffffff'
export const IconButtonPrimaryColorDestructiveHoveredOutline = '#ffffff00'
export const IconButtonPrimaryColorDestructivePressedBackground = '#c9451c'
export const IconButtonPrimaryColorDestructivePressedOnBackground = '#ffffff'
export const IconButtonPrimaryColorDestructivePressedOutline = '#ffffff00'
export const IconButtonPrimaryColorDestructiveDisabledBackground = '#0000000a'
export const IconButtonPrimaryColorDestructiveDisabledOnBackground = '#00000061'
export const IconButtonPrimaryColorDestructiveDisabledOutline = '#ffffff00'
export const IconButtonPrimaryColorWarningDefaultBackground = '#fb8c00'
export const IconButtonPrimaryColorWarningDefaultOnBackground = '#ffffff'
export const IconButtonPrimaryColorWarningDefaultOutline = '#ffffff00'
export const IconButtonPrimaryColorWarningHoveredBackground = '#e58007'
export const IconButtonPrimaryColorWarningHoveredOnBackground = '#ffffff'
export const IconButtonPrimaryColorWarningHoveredOutline = '#ffffff00'
export const IconButtonPrimaryColorWarningPressedBackground = '#cf740d'
export const IconButtonPrimaryColorWarningPressedOnBackground = '#ffffff'
export const IconButtonPrimaryColorWarningPressedOutline = '#ffffff00'
export const IconButtonPrimaryColorWarningDisabledBackground = '#0000000a'
export const IconButtonPrimaryColorWarningDisabledOnBackground = '#00000061'
export const IconButtonPrimaryColorWarningDisabledOutline = '#ffffff00'
