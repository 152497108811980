/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export interface ITokens {
  ThemeColorCommonOnSurfaceLink: '#DE8A3D'
  ThemeColorCommonSecondary: '#ED9341'
  ThemeColorCommonOnSecondary: '#ffffff'
  ThemeColorCommonSecondaryContainer: '#fff4ec'
  ThemeColorCommonWarningContainer: '#fff3e0'
  ThemeColorCommonOnWarningContainer: '#664A33'
  ThemeColorCommonSurface: '#ffffff'
  ThemeColorCommonOnSurfaceOpaque: '#000000'
  ThemeColorCommonOnSurfacePrimary: '#000000de'
  ThemeColorCommonOnSurfaceSecondary: '#00000099'
  ThemeColorCommonOnSurfaceTertiary: '#00000061'
  ThemeColorCommonOnSurfaceContainer: '#f5f5f5'
  ThemeColorCommonOutline: '#0000001f'
  ThemeColorCommonSeparator: '#00000014'
  ThemeColorCommonBackground: '#f5f5f5'
  ThemeColorCommonPrimary: '#004D3B'
  ThemeColorCommonOnPrimary: '#ffffff'
  ThemeColorCommonPrimaryContainer: '#f5f7f6'
  ThemeColorCommonOnPrimaryContainer: '#004D3B'
  ThemeColorCommonOnPrimaryLink: '#71B69F'
  ThemeColorCommonOnSecondaryContainer: '#ED9341'
  ThemeColorCommonInfo: '#546e7a'
  ThemeColorCommonOnInfo: '#ffffff'
  ThemeColorCommonInfoContainer: '#eceff1'
  ThemeColorCommonOnInfoContainer: '#37474f'
  ThemeColorCommonError: '#f4511e'
  ThemeColorCommonOnError: '#ffffff'
  ThemeColorCommonErrorContainer: '#fbe9e7'
  ThemeColorCommonOnErrorContainer: '#d84315'
  ThemeColorCommonWarning: '#fb8c00'
  ThemeColorCommonOnWarning: '#ffffff'
  ThemeColorCommonSuccess: '#7cb342'
  ThemeColorCommonOnSuccess: '#ffffff'
  ThemeColorCommonSuccessContainer: '#f1f8e9'
  ThemeColorCommonOnSuccessContainer: '#558b2f'
  ThemeColorCommonDestructive: '#f4511e'
  ThemeColorCommonOnDestructive: '#ffffff'
  ThemeColorCommonDestructiveContainer: '#fbe9e7'
  ThemeColorCommonOnDestructiveContainer: '#d84315'
  ThemeColorCommonNone: '#ffffff00'
  ThemeColorInvoiceStatusDraftBackground: '#fafafa'
  ThemeColorInvoiceStatusDraftTextOnBackground: '#424242'
  ThemeColorInvoiceStatusDraftTextOnSurface: '#757575'
  ThemeColorInvoiceStatusDraftIcon: '#9e9e9e'
  ThemeColorInvoiceStatusDraftOutline: '#eeeeee'
  ThemeColorInvoiceStatusUnscheduledBackground: '#eceff1'
  ThemeColorInvoiceStatusUnscheduledTextOnBackground: '#37474f'
  ThemeColorInvoiceStatusUnscheduledTextOnSurface: '#546e7a'
  ThemeColorInvoiceStatusUnscheduledIcon: '#607d8b'
  ThemeColorInvoiceStatusUnscheduledOutline: '#b0bec5'
  ThemeColorInvoiceStatusInReviewBackground: '#e8eaf6'
  ThemeColorInvoiceStatusInReviewTextOnBackground: '#283593'
  ThemeColorInvoiceStatusInReviewTextOnSurface: '#3949ab'
  ThemeColorInvoiceStatusInReviewIcon: '#3f51b5'
  ThemeColorInvoiceStatusInReviewOutline: '#9fa8da'
  ThemeColorInvoiceStatusApprovedBackground: '#f1f8e9'
  ThemeColorInvoiceStatusApprovedTextOnBackground: '#33691e'
  ThemeColorInvoiceStatusApprovedTextOnSurface: '#689f38'
  ThemeColorInvoiceStatusApprovedIcon: '#8bc34a'
  ThemeColorInvoiceStatusApprovedOutline: '#aed581'
  ThemeColorInvoiceStatusRejectedBackground: '#fbe9e7'
  ThemeColorInvoiceStatusRejectedTextOnBackground: '#d84315'
  ThemeColorInvoiceStatusRejectedTextOnSurface: '#f4511e'
  ThemeColorInvoiceStatusRejectedIcon: '#f4511e'
  ThemeColorInvoiceStatusRejectedOutline: '#ff8a65'
  ThemeColorInvoiceStatusScheduledBackground: '#e3f2fd'
  ThemeColorInvoiceStatusScheduledTextOnBackground: '#1565c0'
  ThemeColorInvoiceStatusScheduledTextOnSurface: '#1565c0'
  ThemeColorInvoiceStatusScheduledIcon: '#2196f3'
  ThemeColorInvoiceStatusScheduledOutline: '#ffffff00'
  ThemeColorInvoiceStatusProcessedBackground: '#f1f8e9'
  ThemeColorInvoiceStatusProcessedTextOnBackground: '#33691e'
  ThemeColorInvoiceStatusProcessedTextOnSurface: '#689f38'
  ThemeColorInvoiceStatusProcessedIcon: '#8bc34a'
  ThemeColorInvoiceStatusProcessedOutline: '#ffffff00'
  ThemeColorInvoiceStatusPaidBackground: '#f1f8e9'
  ThemeColorInvoiceStatusPaidTextOnBackground: '#33691e'
  ThemeColorInvoiceStatusPaidTextOnSurface: '#689f38'
  ThemeColorInvoiceStatusPaidIcon: '#8bc34a'
  ThemeColorInvoiceStatusPaidOutline: '#ffffff00'
  ThemeColorInvoiceStatusFailedBackground: '#fbe9e7'
  ThemeColorInvoiceStatusFailedTextOnBackground: '#d84315'
  ThemeColorInvoiceStatusFailedTextOnSurface: '#f4511e'
  ThemeColorInvoiceStatusFailedIcon: '#f4511e'
  ThemeColorInvoiceStatusFailedOutline: '#ffffff00'
  ThemeColorInvoiceStatusRefundedBackground: '#eceff1'
  ThemeColorInvoiceStatusRefundedTextOnBackground: '#37474f'
  ThemeColorInvoiceStatusRefundedTextOnSurface: '#546e7a'
  ThemeColorInvoiceStatusRefundedIcon: '#607d8b'
  ThemeColorInvoiceStatusRefundedOutline: '#ffffff00'
  ThemeColorInvoiceStatusPartPaidBackground: '#fff3e0'
  ThemeColorInvoiceStatusPartPaidTextOnBackground: '#ef6c00'
  ThemeColorInvoiceStatusPartPaidTextOnSurface: '#fb8c00'
  ThemeColorInvoiceStatusPartPaidIcon: '#ff9800'
  ThemeColorInvoiceStatusPartPaidOutline: '#ffffff00'
  ThemeColorInvoiceStatusDeletedBackground: '#f5f5f5'
  ThemeColorInvoiceStatusDeletedTextOnBackground: '#757575'
  ThemeColorInvoiceStatusDeletedTextOnSurface: '#757575'
  ThemeColorInvoiceStatusDeletedIcon: '#9e9e9e'
  ThemeColorInvoiceStatusDeletedOutline: '#ffffff00'
  ThemeOpacityStateDefault: '0.04px'
  ThemeOpacityStateHovered: '0.08px'
  ThemeOpacityStateHoveredInverted: '0.92px'
  ThemeOpacityStatePressed: '0.16px'
  ThemeOpacityStatePressedInverted: '0.84px'
  ThemeOpacityStateFocused: '0.12px'
  ThemeOpacityStateFocusedInverted: '0.88px'
  ThemeOpacityStateDisabled: '0.04px'
  ThemeBorderRadiusDesktopLarge: '6px'
  ThemeBorderRadiusDesktopMedium: '5px'
  ThemeBorderRadiusDesktopSmall: '4px'
  ThemeBorderRadiusDesktopNone: '0px'
  ThemeBorderRadiusMobileLarge: '6px'
  ThemeBorderRadiusMobileMedium: '5px'
  ThemeBorderRadiusMobileSmall: '4px'
  ThemeBorderRadiusMobileNone: '0px'
  ThemeTypographyDesktopOverlineFontFamily: 'Inter'
  ThemeTypographyDesktopOverlineFontWeight: 400
  ThemeTypographyDesktopOverlineLineHeight: '16px'
  ThemeTypographyDesktopOverlineFontSize: '10px'
  ThemeTypographyDesktopOverlineLetterSpacing: '0.1px'
  ThemeTypographyDesktopOverlineParagraphSpacing: '0px'
  ThemeTypographyDesktopOverlineTextDecoration: ' '
  ThemeTypographyDesktopOverlineTextCase: ' '
  ThemeTypographyDesktopCaptionFontFamily: 'Inter'
  ThemeTypographyDesktopCaptionFontWeight: 400
  ThemeTypographyDesktopCaptionLineHeight: '16px'
  ThemeTypographyDesktopCaptionFontSize: '12px'
  ThemeTypographyDesktopCaptionLetterSpacing: '0.4px'
  ThemeTypographyDesktopCaptionParagraphSpacing: '0px'
  ThemeTypographyDesktopCaptionTextDecoration: ' '
  ThemeTypographyDesktopCaptionTextCase: ' '
  ThemeTypographyDesktopButtonFontFamily: 'Inter'
  ThemeTypographyDesktopButtonFontWeight: 500
  ThemeTypographyDesktopButtonLineHeight: '24px'
  ThemeTypographyDesktopButtonFontSize: '14px'
  ThemeTypographyDesktopButtonLetterSpacing: '0.25px'
  ThemeTypographyDesktopButtonParagraphSpacing: '0px'
  ThemeTypographyDesktopButtonTextDecoration: ' '
  ThemeTypographyDesktopButtonTextCase: ' '
  ThemeTypographyDesktopBody2FontFamily: 'Inter'
  ThemeTypographyDesktopBody2FontWeight: 400
  ThemeTypographyDesktopBody2LineHeight: '20px'
  ThemeTypographyDesktopBody2FontSize: '14px'
  ThemeTypographyDesktopBody2LetterSpacing: '0.25px'
  ThemeTypographyDesktopBody2ParagraphSpacing: '0px'
  ThemeTypographyDesktopBody2TextDecoration: ' '
  ThemeTypographyDesktopBody2TextCase: ' '
  ThemeTypographyDesktopBody1FontFamily: 'Inter'
  ThemeTypographyDesktopBody1FontWeight: 400
  ThemeTypographyDesktopBody1LineHeight: '24px'
  ThemeTypographyDesktopBody1FontSize: '16px'
  ThemeTypographyDesktopBody1LetterSpacing: '0.25px'
  ThemeTypographyDesktopBody1ParagraphSpacing: '0px'
  ThemeTypographyDesktopBody1TextDecoration: ' '
  ThemeTypographyDesktopBody1TextCase: ' '
  ThemeTypographyDesktopSubtitle2FontFamily: 'Inter'
  ThemeTypographyDesktopSubtitle2FontWeight: 600
  ThemeTypographyDesktopSubtitle2LineHeight: '20px'
  ThemeTypographyDesktopSubtitle2FontSize: '14px'
  ThemeTypographyDesktopSubtitle2LetterSpacing: '0.1px'
  ThemeTypographyDesktopSubtitle2ParagraphSpacing: '0px'
  ThemeTypographyDesktopSubtitle2TextDecoration: ''
  ThemeTypographyDesktopSubtitle2TextCase: ''
  ThemeTypographyDesktopSubtitle1FontFamily: 'Inter'
  ThemeTypographyDesktopSubtitle1LineHeight: '24px'
  ThemeTypographyDesktopSubtitle1FontSize: '16px'
  ThemeTypographyDesktopSubtitle1LetterSpacing: '0.15px'
  ThemeTypographyDesktopSubtitle1ParagraphSpacing: '0px'
  ThemeTypographyDesktopSubtitle1TextDecoration: ''
  ThemeTypographyDesktopSubtitle1TextCase: ''
  ThemeTypographyDesktopSubtitle1FontWeight: 600
  ThemeTypographyDesktopH6FontFamily: 'Inter'
  ThemeTypographyDesktopH6FontWeight: 500
  ThemeTypographyDesktopH6LineHeight: '28px'
  ThemeTypographyDesktopH6FontSize: '20px'
  ThemeTypographyDesktopH6LetterSpacing: '0.15px'
  ThemeTypographyDesktopH6ParagraphSpacing: '0px'
  ThemeTypographyDesktopH6TextDecoration: ' '
  ThemeTypographyDesktopH6TextCase: ' '
  ThemeTypographyDesktopH5FontFamily: 'Inter'
  ThemeTypographyDesktopH5FontWeight: 500
  ThemeTypographyDesktopH5LineHeight: '32px'
  ThemeTypographyDesktopH5FontSize: '24px'
  ThemeTypographyDesktopH5LetterSpacing: '0px'
  ThemeTypographyDesktopH5ParagraphSpacing: '0px'
  ThemeTypographyDesktopH5TextDecoration: ' '
  ThemeTypographyDesktopH5TextCase: ' '
  ThemeTypographyDesktopH4FontFamily: 'Inter'
  ThemeTypographyDesktopH4FontWeight: 500
  ThemeTypographyDesktopH4LineHeight: '40px'
  ThemeTypographyDesktopH4FontSize: '34px'
  ThemeTypographyDesktopH4LetterSpacing: '0.25px'
  ThemeTypographyDesktopH4ParagraphSpacing: '0px'
  ThemeTypographyDesktopH4TextDecoration: ' '
  ThemeTypographyDesktopH4TextCase: ' '
  ThemeTypographyDesktopH3FontFamily: 'Playfair Display'
  ThemeTypographyDesktopH3FontWeight: 400
  ThemeTypographyDesktopH3LineHeight: '64px'
  ThemeTypographyDesktopH3FontSize: '48px'
  ThemeTypographyDesktopH3LetterSpacing: '0px'
  ThemeTypographyDesktopH3ParagraphSpacing: '0px'
  ThemeTypographyDesktopH3TextDecoration: ' '
  ThemeTypographyDesktopH3TextCase: ' '
  ThemeTypographyDesktopH2FontFamily: 'Playfair Display'
  ThemeTypographyDesktopH2FontWeight: 400
  ThemeTypographyDesktopH2LineHeight: '72px'
  ThemeTypographyDesktopH2FontSize: '60px'
  ThemeTypographyDesktopH2LetterSpacing: '-0.5px'
  ThemeTypographyDesktopH2ParagraphSpacing: '0px'
  ThemeTypographyDesktopH2TextDecoration: ' '
  ThemeTypographyDesktopH2TextCase: ' '
  ThemeTypographyDesktopH1FontFamily: 'Playfair Display'
  ThemeTypographyDesktopH1FontWeight: 400
  ThemeTypographyDesktopH1LineHeight: '112px'
  ThemeTypographyDesktopH1FontSize: '96px'
  ThemeTypographyDesktopH1LetterSpacing: '-1.5px'
  ThemeTypographyDesktopH1ParagraphSpacing: '0px'
  ThemeTypographyDesktopH1TextDecoration: ' '
  ThemeTypographyDesktopH1TextCase: ' '
  ThemeTypographyMobileOverlineFontFamily: 'Inter'
  ThemeTypographyMobileOverlineFontWeight: 400
  ThemeTypographyMobileOverlineLineHeight: '16px'
  ThemeTypographyMobileOverlineFontSize: '10px'
  ThemeTypographyMobileOverlineLetterSpacing: '0.1px'
  ThemeTypographyMobileOverlineParagraphSpacing: '0px'
  ThemeTypographyMobileOverlineTextDecoration: ' '
  ThemeTypographyMobileOverlineTextCase: ' '
  ThemeTypographyMobileCaptionFontFamily: 'Inter'
  ThemeTypographyMobileCaptionFontWeight: 400
  ThemeTypographyMobileCaptionLineHeight: '16px'
  ThemeTypographyMobileCaptionFontSize: '12px'
  ThemeTypographyMobileCaptionLetterSpacing: '0.4px'
  ThemeTypographyMobileCaptionParagraphSpacing: '0px'
  ThemeTypographyMobileCaptionTextDecoration: ' '
  ThemeTypographyMobileCaptionTextCase: ' '
  ThemeTypographyMobileButtonFontFamily: 'Inter'
  ThemeTypographyMobileButtonFontWeight: 500
  ThemeTypographyMobileButtonLineHeight: '24px'
  ThemeTypographyMobileButtonFontSize: '14px'
  ThemeTypographyMobileButtonLetterSpacing: '0.25px'
  ThemeTypographyMobileButtonParagraphSpacing: '0px'
  ThemeTypographyMobileButtonTextDecoration: ' '
  ThemeTypographyMobileButtonTextCase: ' '
  ThemeTypographyMobileBody2FontFamily: 'Inter'
  ThemeTypographyMobileBody2FontWeight: 400
  ThemeTypographyMobileBody2LineHeight: '20px'
  ThemeTypographyMobileBody2FontSize: '14px'
  ThemeTypographyMobileBody2LetterSpacing: '0.25px'
  ThemeTypographyMobileBody2ParagraphSpacing: '0px'
  ThemeTypographyMobileBody2TextDecoration: ' '
  ThemeTypographyMobileBody2TextCase: ' '
  ThemeTypographyMobileBody1FontFamily: 'Inter'
  ThemeTypographyMobileBody1FontWeight: 400
  ThemeTypographyMobileBody1LineHeight: '24px'
  ThemeTypographyMobileBody1FontSize: '16px'
  ThemeTypographyMobileBody1LetterSpacing: '0.25px'
  ThemeTypographyMobileBody1ParagraphSpacing: '0px'
  ThemeTypographyMobileBody1TextDecoration: ' '
  ThemeTypographyMobileBody1TextCase: ' '
  ThemeTypographyMobileSubtitle2FontFamily: 'Inter'
  ThemeTypographyMobileSubtitle2FontWeight: 600
  ThemeTypographyMobileSubtitle2LineHeight: '20px'
  ThemeTypographyMobileSubtitle2FontSize: '14px'
  ThemeTypographyMobileSubtitle2LetterSpacing: '0.1px'
  ThemeTypographyMobileSubtitle2ParagraphSpacing: '0px'
  ThemeTypographyMobileSubtitle2TextDecoration: ''
  ThemeTypographyMobileSubtitle2TextCase: ''
  ThemeTypographyMobileSubtitle1FontFamily: 'Inter'
  ThemeTypographyMobileSubtitle1FontWeight: 600
  ThemeTypographyMobileSubtitle1LineHeight: '24px'
  ThemeTypographyMobileSubtitle1FontSize: '16px'
  ThemeTypographyMobileSubtitle1LetterSpacing: '0.15px'
  ThemeTypographyMobileSubtitle1ParagraphSpacing: '0px'
  ThemeTypographyMobileSubtitle1TextDecoration: ''
  ThemeTypographyMobileSubtitle1TextCase: ''
  ThemeTypographyMobileH6FontFamily: 'Inter'
  ThemeTypographyMobileH6FontWeight: 500
  ThemeTypographyMobileH6LineHeight: '28px'
  ThemeTypographyMobileH6FontSize: '20px'
  ThemeTypographyMobileH6LetterSpacing: '0.15px'
  ThemeTypographyMobileH6ParagraphSpacing: '0px'
  ThemeTypographyMobileH6TextDecoration: ' '
  ThemeTypographyMobileH6TextCase: ' '
  ThemeTypographyMobileH5FontFamily: 'Inter'
  ThemeTypographyMobileH5FontWeight: 500
  ThemeTypographyMobileH5LineHeight: '32px'
  ThemeTypographyMobileH5FontSize: '24px'
  ThemeTypographyMobileH5LetterSpacing: '0px'
  ThemeTypographyMobileH5ParagraphSpacing: '0px'
  ThemeTypographyMobileH5TextDecoration: ' '
  ThemeTypographyMobileH5TextCase: ' '
  ThemeTypographyMobileH4FontFamily: 'Inter'
  ThemeTypographyMobileH4FontWeight: 500
  ThemeTypographyMobileH4LineHeight: '40px'
  ThemeTypographyMobileH4FontSize: '34px'
  ThemeTypographyMobileH4LetterSpacing: '0.25px'
  ThemeTypographyMobileH4ParagraphSpacing: '0px'
  ThemeTypographyMobileH4TextDecoration: ' '
  ThemeTypographyMobileH4TextCase: ' '
  ThemeTypographyMobileH3FontFamily: 'Playfair Display'
  ThemeTypographyMobileH3FontWeight: 400
  ThemeTypographyMobileH3LineHeight: '64px'
  ThemeTypographyMobileH3FontSize: '48px'
  ThemeTypographyMobileH3LetterSpacing: '0px'
  ThemeTypographyMobileH3ParagraphSpacing: '0px'
  ThemeTypographyMobileH3TextDecoration: ' '
  ThemeTypographyMobileH3TextCase: ' '
  ThemeTypographyMobileH2FontFamily: 'Playfair Display'
  ThemeTypographyMobileH2FontWeight: 400
  ThemeTypographyMobileH2LineHeight: '72px'
  ThemeTypographyMobileH2FontSize: '60px'
  ThemeTypographyMobileH2LetterSpacing: '-0.5px'
  ThemeTypographyMobileH2ParagraphSpacing: '0px'
  ThemeTypographyMobileH2TextDecoration: ' '
  ThemeTypographyMobileH2TextCase: ' '
  ThemeTypographyMobileH1FontFamily: 'Playfair Display'
  ThemeTypographyMobileH1FontWeight: 400
  ThemeTypographyMobileH1LineHeight: '112px'
  ThemeTypographyMobileH1FontSize: '96px'
  ThemeTypographyMobileH1LetterSpacing: '-1.5px'
  ThemeTypographyMobileH1ParagraphSpacing: '0px'
  ThemeTypographyMobileH1TextDecoration: ' '
  ThemeTypographyMobileH1TextCase: ' '
  ThemeShadowDesktopLargeX: '0px'
  ThemeShadowDesktopLargeY: '0px'
  ThemeShadowDesktopLargeBlur: '24px'
  ThemeShadowDesktopLargeSpread: '0px'
  ThemeShadowDesktopLargeColor: '#00000026'
  ThemeShadowDesktopLargeType: 'dropShadow'
  ThemeShadowDesktopMediumX: '0px'
  ThemeShadowDesktopMediumY: '0px'
  ThemeShadowDesktopMediumBlur: '16px'
  ThemeShadowDesktopMediumSpread: '0px'
  ThemeShadowDesktopMediumColor: '#00000026'
  ThemeShadowDesktopMediumType: 'dropShadow'
  ThemeShadowDesktopSmallX: '0px'
  ThemeShadowDesktopSmallY: '0px'
  ThemeShadowDesktopSmallBlur: '8px'
  ThemeShadowDesktopSmallSpread: '0px'
  ThemeShadowDesktopSmallColor: '#00000026'
  ThemeShadowDesktopSmallType: 'dropShadow'
  ThemeShadowMobileLargeX: '0px'
  ThemeShadowMobileLargeY: '0px'
  ThemeShadowMobileLargeBlur: '24px'
  ThemeShadowMobileLargeSpread: '0px'
  ThemeShadowMobileLargeColor: '#00000026'
  ThemeShadowMobileLargeType: 'dropShadow'
  ThemeShadowMobileMediumX: '0px'
  ThemeShadowMobileMediumY: '0px'
  ThemeShadowMobileMediumBlur: '16px'
  ThemeShadowMobileMediumSpread: '0px'
  ThemeShadowMobileMediumColor: '#00000026'
  ThemeShadowMobileMediumType: 'dropShadow'
  ThemeShadowMobileSmallX: '0px'
  ThemeShadowMobileSmallY: '0px'
  ThemeShadowMobileSmallBlur: '8px'
  ThemeShadowMobileSmallSpread: '0px'
  ThemeShadowMobileSmallColor: '#00000026'
  ThemeShadowMobileSmallType: 'dropShadow'
  ThemeSizeIconDesktopSmall: '16px'
  ThemeSizeIconDesktopMedium: '24px'
  ThemeSizeIconDesktopLarge: '32px'
  ThemeSizeIconMobileSmall: '16px'
  ThemeSizeIconMobileMedium: '24px'
  ThemeSizeIconMobileLarge: '32px'
  ThemeSizeShapeDesktopXsmall: '24px'
  ThemeSizeShapeDesktopSmall: '32px'
  ThemeSizeShapeDesktopMedium: '40px'
  ThemeSizeShapeDesktopLarge: '48px'
  ThemeSizeShapeDesktopXlarge: '56px'
  ThemeSizeShapeDesktopXxlarge: '96px'
  ThemeSizeShapeDesktopXxxlarge: '112px'
  ThemeSizeShapeMobileXsmall: '24px'
  ThemeSizeShapeMobileSmall: '32px'
  ThemeSizeShapeMobileMedium: '40px'
  ThemeSizeShapeMobileLarge: '48px'
  ThemeSizeShapeMobileXlarge: '56px'
  ThemeSizeShapeMobileXxlarge: '96px'
  ThemeSizeShapeMobileXxxlarge: '112px'
}
export const ThemeColorCommonOnSurfaceLink = '#DE8A3D'
export const ThemeColorCommonSecondary = '#ED9341'
export const ThemeColorCommonOnSecondary = '#ffffff'
export const ThemeColorCommonSecondaryContainer = '#fff4ec'
export const ThemeColorCommonWarningContainer = '#fff3e0'
export const ThemeColorCommonOnWarningContainer = '#664A33'
export const ThemeColorCommonSurface = '#ffffff'
export const ThemeColorCommonOnSurfaceOpaque = '#000000'
export const ThemeColorCommonOnSurfacePrimary = '#000000de'
export const ThemeColorCommonOnSurfaceSecondary = '#00000099'
export const ThemeColorCommonOnSurfaceTertiary = '#00000061'
export const ThemeColorCommonOnSurfaceContainer = '#f5f5f5'
export const ThemeColorCommonOutline = '#0000001f'
export const ThemeColorCommonSeparator = '#00000014'
export const ThemeColorCommonBackground = '#f5f5f5'
export const ThemeColorCommonPrimary = '#004D3B'
export const ThemeColorCommonOnPrimary = '#ffffff'
export const ThemeColorCommonPrimaryContainer = '#f5f7f6'
export const ThemeColorCommonOnPrimaryContainer = '#004D3B'
export const ThemeColorCommonOnPrimaryLink = '#71B69F'
export const ThemeColorCommonOnSecondaryContainer = '#ED9341'
export const ThemeColorCommonInfo = '#546e7a'
export const ThemeColorCommonOnInfo = '#ffffff'
export const ThemeColorCommonInfoContainer = '#eceff1'
export const ThemeColorCommonOnInfoContainer = '#37474f'
export const ThemeColorCommonError = '#f4511e'
export const ThemeColorCommonOnError = '#ffffff'
export const ThemeColorCommonErrorContainer = '#fbe9e7'
export const ThemeColorCommonOnErrorContainer = '#d84315'
export const ThemeColorCommonWarning = '#fb8c00'
export const ThemeColorCommonOnWarning = '#ffffff'
export const ThemeColorCommonSuccess = '#7cb342'
export const ThemeColorCommonOnSuccess = '#ffffff'
export const ThemeColorCommonSuccessContainer = '#f1f8e9'
export const ThemeColorCommonOnSuccessContainer = '#558b2f'
export const ThemeColorCommonDestructive = '#f4511e'
export const ThemeColorCommonOnDestructive = '#ffffff'
export const ThemeColorCommonDestructiveContainer = '#fbe9e7'
export const ThemeColorCommonOnDestructiveContainer = '#d84315'
export const ThemeColorCommonNone = '#ffffff00'
export const ThemeColorInvoiceStatusDraftBackground = '#fafafa'
export const ThemeColorInvoiceStatusDraftTextOnBackground = '#424242'
export const ThemeColorInvoiceStatusDraftTextOnSurface = '#757575'
export const ThemeColorInvoiceStatusDraftIcon = '#9e9e9e'
export const ThemeColorInvoiceStatusDraftOutline = '#eeeeee'
export const ThemeColorInvoiceStatusUnscheduledBackground = '#eceff1'
export const ThemeColorInvoiceStatusUnscheduledTextOnBackground = '#37474f'
export const ThemeColorInvoiceStatusUnscheduledTextOnSurface = '#546e7a'
export const ThemeColorInvoiceStatusUnscheduledIcon = '#607d8b'
export const ThemeColorInvoiceStatusUnscheduledOutline = '#b0bec5'
export const ThemeColorInvoiceStatusInReviewBackground = '#e8eaf6'
export const ThemeColorInvoiceStatusInReviewTextOnBackground = '#283593'
export const ThemeColorInvoiceStatusInReviewTextOnSurface = '#3949ab'
export const ThemeColorInvoiceStatusInReviewIcon = '#3f51b5'
export const ThemeColorInvoiceStatusInReviewOutline = '#9fa8da'
export const ThemeColorInvoiceStatusApprovedBackground = '#f1f8e9'
export const ThemeColorInvoiceStatusApprovedTextOnBackground = '#33691e'
export const ThemeColorInvoiceStatusApprovedTextOnSurface = '#689f38'
export const ThemeColorInvoiceStatusApprovedIcon = '#8bc34a'
export const ThemeColorInvoiceStatusApprovedOutline = '#aed581'
export const ThemeColorInvoiceStatusRejectedBackground = '#fbe9e7'
export const ThemeColorInvoiceStatusRejectedTextOnBackground = '#d84315'
export const ThemeColorInvoiceStatusRejectedTextOnSurface = '#f4511e'
export const ThemeColorInvoiceStatusRejectedIcon = '#f4511e'
export const ThemeColorInvoiceStatusRejectedOutline = '#ff8a65'
export const ThemeColorInvoiceStatusScheduledBackground = '#e3f2fd'
export const ThemeColorInvoiceStatusScheduledTextOnBackground = '#1565c0'
export const ThemeColorInvoiceStatusScheduledTextOnSurface = '#1565c0'
export const ThemeColorInvoiceStatusScheduledIcon = '#2196f3'
export const ThemeColorInvoiceStatusScheduledOutline = '#ffffff00'
export const ThemeColorInvoiceStatusProcessedBackground = '#f1f8e9'
export const ThemeColorInvoiceStatusProcessedTextOnBackground = '#33691e'
export const ThemeColorInvoiceStatusProcessedTextOnSurface = '#689f38'
export const ThemeColorInvoiceStatusProcessedIcon = '#8bc34a'
export const ThemeColorInvoiceStatusProcessedOutline = '#ffffff00'
export const ThemeColorInvoiceStatusPaidBackground = '#f1f8e9'
export const ThemeColorInvoiceStatusPaidTextOnBackground = '#33691e'
export const ThemeColorInvoiceStatusPaidTextOnSurface = '#689f38'
export const ThemeColorInvoiceStatusPaidIcon = '#8bc34a'
export const ThemeColorInvoiceStatusPaidOutline = '#ffffff00'
export const ThemeColorInvoiceStatusFailedBackground = '#fbe9e7'
export const ThemeColorInvoiceStatusFailedTextOnBackground = '#d84315'
export const ThemeColorInvoiceStatusFailedTextOnSurface = '#f4511e'
export const ThemeColorInvoiceStatusFailedIcon = '#f4511e'
export const ThemeColorInvoiceStatusFailedOutline = '#ffffff00'
export const ThemeColorInvoiceStatusRefundedBackground = '#eceff1'
export const ThemeColorInvoiceStatusRefundedTextOnBackground = '#37474f'
export const ThemeColorInvoiceStatusRefundedTextOnSurface = '#546e7a'
export const ThemeColorInvoiceStatusRefundedIcon = '#607d8b'
export const ThemeColorInvoiceStatusRefundedOutline = '#ffffff00'
export const ThemeColorInvoiceStatusPartPaidBackground = '#fff3e0'
export const ThemeColorInvoiceStatusPartPaidTextOnBackground = '#ef6c00'
export const ThemeColorInvoiceStatusPartPaidTextOnSurface = '#fb8c00'
export const ThemeColorInvoiceStatusPartPaidIcon = '#ff9800'
export const ThemeColorInvoiceStatusPartPaidOutline = '#ffffff00'
export const ThemeColorInvoiceStatusDeletedBackground = '#f5f5f5'
export const ThemeColorInvoiceStatusDeletedTextOnBackground = '#757575'
export const ThemeColorInvoiceStatusDeletedTextOnSurface = '#757575'
export const ThemeColorInvoiceStatusDeletedIcon = '#9e9e9e'
export const ThemeColorInvoiceStatusDeletedOutline = '#ffffff00'
export const ThemeOpacityStateDefault = '0.04px'
export const ThemeOpacityStateHovered = '0.08px'
export const ThemeOpacityStateHoveredInverted = '0.92px'
export const ThemeOpacityStatePressed = '0.16px'
export const ThemeOpacityStatePressedInverted = '0.84px'
export const ThemeOpacityStateFocused = '0.12px'
export const ThemeOpacityStateFocusedInverted = '0.88px'
export const ThemeOpacityStateDisabled = '0.04px'
export const ThemeBorderRadiusDesktopLarge = '6px'
export const ThemeBorderRadiusDesktopMedium = '5px'
export const ThemeBorderRadiusDesktopSmall = '4px'
export const ThemeBorderRadiusDesktopNone = '0px'
export const ThemeBorderRadiusMobileLarge = '6px'
export const ThemeBorderRadiusMobileMedium = '5px'
export const ThemeBorderRadiusMobileSmall = '4px'
export const ThemeBorderRadiusMobileNone = '0px'
export const ThemeTypographyDesktopOverlineFontFamily = 'Inter'
export const ThemeTypographyDesktopOverlineFontWeight = 400
export const ThemeTypographyDesktopOverlineLineHeight = '16px'
export const ThemeTypographyDesktopOverlineFontSize = '10px'
export const ThemeTypographyDesktopOverlineLetterSpacing = '0.1px'
export const ThemeTypographyDesktopOverlineParagraphSpacing = '0px'
export const ThemeTypographyDesktopOverlineTextDecoration = ' '
export const ThemeTypographyDesktopOverlineTextCase = ' '
export const ThemeTypographyDesktopCaptionFontFamily = 'Inter'
export const ThemeTypographyDesktopCaptionFontWeight = 400
export const ThemeTypographyDesktopCaptionLineHeight = '16px'
export const ThemeTypographyDesktopCaptionFontSize = '12px'
export const ThemeTypographyDesktopCaptionLetterSpacing = '0.4px'
export const ThemeTypographyDesktopCaptionParagraphSpacing = '0px'
export const ThemeTypographyDesktopCaptionTextDecoration = ' '
export const ThemeTypographyDesktopCaptionTextCase = ' '
export const ThemeTypographyDesktopButtonFontFamily = 'Inter'
export const ThemeTypographyDesktopButtonFontWeight = 500
export const ThemeTypographyDesktopButtonLineHeight = '24px'
export const ThemeTypographyDesktopButtonFontSize = '14px'
export const ThemeTypographyDesktopButtonLetterSpacing = '0.25px'
export const ThemeTypographyDesktopButtonParagraphSpacing = '0px'
export const ThemeTypographyDesktopButtonTextDecoration = ' '
export const ThemeTypographyDesktopButtonTextCase = ' '
export const ThemeTypographyDesktopBody2FontFamily = 'Inter'
export const ThemeTypographyDesktopBody2FontWeight = 400
export const ThemeTypographyDesktopBody2LineHeight = '20px'
export const ThemeTypographyDesktopBody2FontSize = '14px'
export const ThemeTypographyDesktopBody2LetterSpacing = '0.25px'
export const ThemeTypographyDesktopBody2ParagraphSpacing = '0px'
export const ThemeTypographyDesktopBody2TextDecoration = ' '
export const ThemeTypographyDesktopBody2TextCase = ' '
export const ThemeTypographyDesktopBody1FontFamily = 'Inter'
export const ThemeTypographyDesktopBody1FontWeight = 400
export const ThemeTypographyDesktopBody1LineHeight = '24px'
export const ThemeTypographyDesktopBody1FontSize = '16px'
export const ThemeTypographyDesktopBody1LetterSpacing = '0.25px'
export const ThemeTypographyDesktopBody1ParagraphSpacing = '0px'
export const ThemeTypographyDesktopBody1TextDecoration = ' '
export const ThemeTypographyDesktopBody1TextCase = ' '
export const ThemeTypographyDesktopSubtitle2FontFamily = 'Inter'
export const ThemeTypographyDesktopSubtitle2FontWeight = 600
export const ThemeTypographyDesktopSubtitle2LineHeight = '20px'
export const ThemeTypographyDesktopSubtitle2FontSize = '14px'
export const ThemeTypographyDesktopSubtitle2LetterSpacing = '0.1px'
export const ThemeTypographyDesktopSubtitle2ParagraphSpacing = '0px'
export const ThemeTypographyDesktopSubtitle2TextDecoration = ''
export const ThemeTypographyDesktopSubtitle2TextCase = ''
export const ThemeTypographyDesktopSubtitle1FontFamily = 'Inter'
export const ThemeTypographyDesktopSubtitle1LineHeight = '24px'
export const ThemeTypographyDesktopSubtitle1FontSize = '16px'
export const ThemeTypographyDesktopSubtitle1LetterSpacing = '0.15px'
export const ThemeTypographyDesktopSubtitle1ParagraphSpacing = '0px'
export const ThemeTypographyDesktopSubtitle1TextDecoration = ''
export const ThemeTypographyDesktopSubtitle1TextCase = ''
export const ThemeTypographyDesktopSubtitle1FontWeight = 600
export const ThemeTypographyDesktopH6FontFamily = 'Inter'
export const ThemeTypographyDesktopH6FontWeight = 500
export const ThemeTypographyDesktopH6LineHeight = '28px'
export const ThemeTypographyDesktopH6FontSize = '20px'
export const ThemeTypographyDesktopH6LetterSpacing = '0.15px'
export const ThemeTypographyDesktopH6ParagraphSpacing = '0px'
export const ThemeTypographyDesktopH6TextDecoration = ' '
export const ThemeTypographyDesktopH6TextCase = ' '
export const ThemeTypographyDesktopH5FontFamily = 'Inter'
export const ThemeTypographyDesktopH5FontWeight = 500
export const ThemeTypographyDesktopH5LineHeight = '32px'
export const ThemeTypographyDesktopH5FontSize = '24px'
export const ThemeTypographyDesktopH5LetterSpacing = '0px'
export const ThemeTypographyDesktopH5ParagraphSpacing = '0px'
export const ThemeTypographyDesktopH5TextDecoration = ' '
export const ThemeTypographyDesktopH5TextCase = ' '
export const ThemeTypographyDesktopH4FontFamily = 'Inter'
export const ThemeTypographyDesktopH4FontWeight = 500
export const ThemeTypographyDesktopH4LineHeight = '40px'
export const ThemeTypographyDesktopH4FontSize = '34px'
export const ThemeTypographyDesktopH4LetterSpacing = '0.25px'
export const ThemeTypographyDesktopH4ParagraphSpacing = '0px'
export const ThemeTypographyDesktopH4TextDecoration = ' '
export const ThemeTypographyDesktopH4TextCase = ' '
export const ThemeTypographyDesktopH3FontFamily = 'Playfair Display'
export const ThemeTypographyDesktopH3FontWeight = 400
export const ThemeTypographyDesktopH3LineHeight = '64px'
export const ThemeTypographyDesktopH3FontSize = '48px'
export const ThemeTypographyDesktopH3LetterSpacing = '0px'
export const ThemeTypographyDesktopH3ParagraphSpacing = '0px'
export const ThemeTypographyDesktopH3TextDecoration = ' '
export const ThemeTypographyDesktopH3TextCase = ' '
export const ThemeTypographyDesktopH2FontFamily = 'Playfair Display'
export const ThemeTypographyDesktopH2FontWeight = 400
export const ThemeTypographyDesktopH2LineHeight = '72px'
export const ThemeTypographyDesktopH2FontSize = '60px'
export const ThemeTypographyDesktopH2LetterSpacing = '-0.5px'
export const ThemeTypographyDesktopH2ParagraphSpacing = '0px'
export const ThemeTypographyDesktopH2TextDecoration = ' '
export const ThemeTypographyDesktopH2TextCase = ' '
export const ThemeTypographyDesktopH1FontFamily = 'Playfair Display'
export const ThemeTypographyDesktopH1FontWeight = 400
export const ThemeTypographyDesktopH1LineHeight = '112px'
export const ThemeTypographyDesktopH1FontSize = '96px'
export const ThemeTypographyDesktopH1LetterSpacing = '-1.5px'
export const ThemeTypographyDesktopH1ParagraphSpacing = '0px'
export const ThemeTypographyDesktopH1TextDecoration = ' '
export const ThemeTypographyDesktopH1TextCase = ' '
export const ThemeTypographyMobileOverlineFontFamily = 'Inter'
export const ThemeTypographyMobileOverlineFontWeight = 400
export const ThemeTypographyMobileOverlineLineHeight = '16px'
export const ThemeTypographyMobileOverlineFontSize = '10px'
export const ThemeTypographyMobileOverlineLetterSpacing = '0.1px'
export const ThemeTypographyMobileOverlineParagraphSpacing = '0px'
export const ThemeTypographyMobileOverlineTextDecoration = ' '
export const ThemeTypographyMobileOverlineTextCase = ' '
export const ThemeTypographyMobileCaptionFontFamily = 'Inter'
export const ThemeTypographyMobileCaptionFontWeight = 400
export const ThemeTypographyMobileCaptionLineHeight = '16px'
export const ThemeTypographyMobileCaptionFontSize = '12px'
export const ThemeTypographyMobileCaptionLetterSpacing = '0.4px'
export const ThemeTypographyMobileCaptionParagraphSpacing = '0px'
export const ThemeTypographyMobileCaptionTextDecoration = ' '
export const ThemeTypographyMobileCaptionTextCase = ' '
export const ThemeTypographyMobileButtonFontFamily = 'Inter'
export const ThemeTypographyMobileButtonFontWeight = 500
export const ThemeTypographyMobileButtonLineHeight = '24px'
export const ThemeTypographyMobileButtonFontSize = '14px'
export const ThemeTypographyMobileButtonLetterSpacing = '0.25px'
export const ThemeTypographyMobileButtonParagraphSpacing = '0px'
export const ThemeTypographyMobileButtonTextDecoration = ' '
export const ThemeTypographyMobileButtonTextCase = ' '
export const ThemeTypographyMobileBody2FontFamily = 'Inter'
export const ThemeTypographyMobileBody2FontWeight = 400
export const ThemeTypographyMobileBody2LineHeight = '20px'
export const ThemeTypographyMobileBody2FontSize = '14px'
export const ThemeTypographyMobileBody2LetterSpacing = '0.25px'
export const ThemeTypographyMobileBody2ParagraphSpacing = '0px'
export const ThemeTypographyMobileBody2TextDecoration = ' '
export const ThemeTypographyMobileBody2TextCase = ' '
export const ThemeTypographyMobileBody1FontFamily = 'Inter'
export const ThemeTypographyMobileBody1FontWeight = 400
export const ThemeTypographyMobileBody1LineHeight = '24px'
export const ThemeTypographyMobileBody1FontSize = '16px'
export const ThemeTypographyMobileBody1LetterSpacing = '0.25px'
export const ThemeTypographyMobileBody1ParagraphSpacing = '0px'
export const ThemeTypographyMobileBody1TextDecoration = ' '
export const ThemeTypographyMobileBody1TextCase = ' '
export const ThemeTypographyMobileSubtitle2FontFamily = 'Inter'
export const ThemeTypographyMobileSubtitle2FontWeight = 600
export const ThemeTypographyMobileSubtitle2LineHeight = '20px'
export const ThemeTypographyMobileSubtitle2FontSize = '14px'
export const ThemeTypographyMobileSubtitle2LetterSpacing = '0.1px'
export const ThemeTypographyMobileSubtitle2ParagraphSpacing = '0px'
export const ThemeTypographyMobileSubtitle2TextDecoration = ''
export const ThemeTypographyMobileSubtitle2TextCase = ''
export const ThemeTypographyMobileSubtitle1FontFamily = 'Inter'
export const ThemeTypographyMobileSubtitle1FontWeight = 600
export const ThemeTypographyMobileSubtitle1LineHeight = '24px'
export const ThemeTypographyMobileSubtitle1FontSize = '16px'
export const ThemeTypographyMobileSubtitle1LetterSpacing = '0.15px'
export const ThemeTypographyMobileSubtitle1ParagraphSpacing = '0px'
export const ThemeTypographyMobileSubtitle1TextDecoration = ''
export const ThemeTypographyMobileSubtitle1TextCase = ''
export const ThemeTypographyMobileH6FontFamily = 'Inter'
export const ThemeTypographyMobileH6FontWeight = 500
export const ThemeTypographyMobileH6LineHeight = '28px'
export const ThemeTypographyMobileH6FontSize = '20px'
export const ThemeTypographyMobileH6LetterSpacing = '0.15px'
export const ThemeTypographyMobileH6ParagraphSpacing = '0px'
export const ThemeTypographyMobileH6TextDecoration = ' '
export const ThemeTypographyMobileH6TextCase = ' '
export const ThemeTypographyMobileH5FontFamily = 'Inter'
export const ThemeTypographyMobileH5FontWeight = 500
export const ThemeTypographyMobileH5LineHeight = '32px'
export const ThemeTypographyMobileH5FontSize = '24px'
export const ThemeTypographyMobileH5LetterSpacing = '0px'
export const ThemeTypographyMobileH5ParagraphSpacing = '0px'
export const ThemeTypographyMobileH5TextDecoration = ' '
export const ThemeTypographyMobileH5TextCase = ' '
export const ThemeTypographyMobileH4FontFamily = 'Inter'
export const ThemeTypographyMobileH4FontWeight = 500
export const ThemeTypographyMobileH4LineHeight = '40px'
export const ThemeTypographyMobileH4FontSize = '34px'
export const ThemeTypographyMobileH4LetterSpacing = '0.25px'
export const ThemeTypographyMobileH4ParagraphSpacing = '0px'
export const ThemeTypographyMobileH4TextDecoration = ' '
export const ThemeTypographyMobileH4TextCase = ' '
export const ThemeTypographyMobileH3FontFamily = 'Playfair Display'
export const ThemeTypographyMobileH3FontWeight = 400
export const ThemeTypographyMobileH3LineHeight = '64px'
export const ThemeTypographyMobileH3FontSize = '48px'
export const ThemeTypographyMobileH3LetterSpacing = '0px'
export const ThemeTypographyMobileH3ParagraphSpacing = '0px'
export const ThemeTypographyMobileH3TextDecoration = ' '
export const ThemeTypographyMobileH3TextCase = ' '
export const ThemeTypographyMobileH2FontFamily = 'Playfair Display'
export const ThemeTypographyMobileH2FontWeight = 400
export const ThemeTypographyMobileH2LineHeight = '72px'
export const ThemeTypographyMobileH2FontSize = '60px'
export const ThemeTypographyMobileH2LetterSpacing = '-0.5px'
export const ThemeTypographyMobileH2ParagraphSpacing = '0px'
export const ThemeTypographyMobileH2TextDecoration = ' '
export const ThemeTypographyMobileH2TextCase = ' '
export const ThemeTypographyMobileH1FontFamily = 'Playfair Display'
export const ThemeTypographyMobileH1FontWeight = 400
export const ThemeTypographyMobileH1LineHeight = '112px'
export const ThemeTypographyMobileH1FontSize = '96px'
export const ThemeTypographyMobileH1LetterSpacing = '-1.5px'
export const ThemeTypographyMobileH1ParagraphSpacing = '0px'
export const ThemeTypographyMobileH1TextDecoration = ' '
export const ThemeTypographyMobileH1TextCase = ' '
export const ThemeShadowDesktopLargeX = '0px'
export const ThemeShadowDesktopLargeY = '0px'
export const ThemeShadowDesktopLargeBlur = '24px'
export const ThemeShadowDesktopLargeSpread = '0px'
export const ThemeShadowDesktopLargeColor = '#00000026'
export const ThemeShadowDesktopLargeType = 'dropShadow'
export const ThemeShadowDesktopMediumX = '0px'
export const ThemeShadowDesktopMediumY = '0px'
export const ThemeShadowDesktopMediumBlur = '16px'
export const ThemeShadowDesktopMediumSpread = '0px'
export const ThemeShadowDesktopMediumColor = '#00000026'
export const ThemeShadowDesktopMediumType = 'dropShadow'
export const ThemeShadowDesktopSmallX = '0px'
export const ThemeShadowDesktopSmallY = '0px'
export const ThemeShadowDesktopSmallBlur = '8px'
export const ThemeShadowDesktopSmallSpread = '0px'
export const ThemeShadowDesktopSmallColor = '#00000026'
export const ThemeShadowDesktopSmallType = 'dropShadow'
export const ThemeShadowMobileLargeX = '0px'
export const ThemeShadowMobileLargeY = '0px'
export const ThemeShadowMobileLargeBlur = '24px'
export const ThemeShadowMobileLargeSpread = '0px'
export const ThemeShadowMobileLargeColor = '#00000026'
export const ThemeShadowMobileLargeType = 'dropShadow'
export const ThemeShadowMobileMediumX = '0px'
export const ThemeShadowMobileMediumY = '0px'
export const ThemeShadowMobileMediumBlur = '16px'
export const ThemeShadowMobileMediumSpread = '0px'
export const ThemeShadowMobileMediumColor = '#00000026'
export const ThemeShadowMobileMediumType = 'dropShadow'
export const ThemeShadowMobileSmallX = '0px'
export const ThemeShadowMobileSmallY = '0px'
export const ThemeShadowMobileSmallBlur = '8px'
export const ThemeShadowMobileSmallSpread = '0px'
export const ThemeShadowMobileSmallColor = '#00000026'
export const ThemeShadowMobileSmallType = 'dropShadow'
export const ThemeSizeIconDesktopSmall = '16px'
export const ThemeSizeIconDesktopMedium = '24px'
export const ThemeSizeIconDesktopLarge = '32px'
export const ThemeSizeIconMobileSmall = '16px'
export const ThemeSizeIconMobileMedium = '24px'
export const ThemeSizeIconMobileLarge = '32px'
export const ThemeSizeShapeDesktopXsmall = '24px'
export const ThemeSizeShapeDesktopSmall = '32px'
export const ThemeSizeShapeDesktopMedium = '40px'
export const ThemeSizeShapeDesktopLarge = '48px'
export const ThemeSizeShapeDesktopXlarge = '56px'
export const ThemeSizeShapeDesktopXxlarge = '96px'
export const ThemeSizeShapeDesktopXxxlarge = '112px'
export const ThemeSizeShapeMobileXsmall = '24px'
export const ThemeSizeShapeMobileSmall = '32px'
export const ThemeSizeShapeMobileMedium = '40px'
export const ThemeSizeShapeMobileLarge = '48px'
export const ThemeSizeShapeMobileXlarge = '56px'
export const ThemeSizeShapeMobileXxlarge = '96px'
export const ThemeSizeShapeMobileXxxlarge = '112px'
